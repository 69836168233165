<template>

  <a-modal
    title="修改头像"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    :width="800"
    :footer="null"
    @cancel="cancelHandel">
    <a-row>
      <a-col :xs="24" :md="12" :style="{height: '350px'}">
        <vue-cropper
          ref="cropper"
          :img="options.img"
          :info="true"
          :autoCrop="options.autoCrop"
          :autoCropWidth="options.autoCropWidth"
          :autoCropHeight="options.autoCropHeight"
          :fixedBox="options.fixedBox"
          @realTime="realTime"
        >
        </vue-cropper>
      </a-col>
      <a-col :xs="24" :md="12" :style="{height: '350px'}">
        <div class="avatar-upload-preview">
          <img :src="previews.url" :style="previews.img"/>
        </div>
      </a-col>
    </a-row>
    <br>
    <a-row>
      <a-col :lg="2" :md="2">
        <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false">
          <a-button icon="upload">选择图片</a-button>
        </a-upload>
      </a-col>
      <a-col :lg="{span: 1, offset: 2}" :md="2">
        <a-button icon="plus" @click="changeScale(1)"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="minus" @click="changeScale(-1)"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="undo" @click="rotateLeft"/>
      </a-col>
      <a-col :lg="{span: 1, offset: 1}" :md="2">
        <a-button icon="redo" @click="rotateRight"/>
      </a-col>
      <a-col :lg="{span: 2, offset: 6}" :md="2">
        <a-button type="primary" @click="finish('blob')">保存</a-button>
      </a-col>
    </a-row>

  </a-modal>

</template>
<script>
  import { upload, updateUserInfo } from '@/api/manage'

  import store from '@/store'
  export default {
    data () {
      return {
        recordId: store.state.user.userId, // 拿到ID
        userLoad: upload.avatar, // 图片上传地址
        visible: false,
        id: null,
        confirmLoading: false,
        fileList: [],
        uploading: false,
        options: {
          // img: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          img: '',
          autoCrop: true,
          autoCropWidth: 200,
          autoCropHeight: 200,
          fixedBox: true
        },
        previews: {}
      }
    },
    methods: {
      edit (id) {
        this.visible = true
        this.id = id
        /* 获取原始头像 */
      },
      close () {
        this.id = null
        this.visible = false
      },
      cancelHandel () {
        this.close()
      },
      changeScale (num) {
        num = num || 1
        this.$refs.cropper.changeScale(num)
      },
      rotateLeft () {
        this.$refs.cropper.rotateLeft()
      },
      rotateRight () {
        this.$refs.cropper.rotateRight()
      },
      beforeUpload (file) {
        const reader = new FileReader()
        // 把Array Buffer转化为blob 如果是base64不需要
        // 转化为base64
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.options.img = reader.result
        }
        // 转化为blob
        // reader.readAsArrayBuffer(file)

        return false
      },

      // 上传图片（点击上传按钮）
      finish (type) {
        console.log('finish')
        const _this = this
        // 通过FormData构造函数创建一个空对象
        const formData = new FormData()
        // 输出
        if (type === 'blob') {
          this.$refs.cropper.getCropBlob((data) => {
            // 通过该方法可以获取当前文件的一个内存URL
            const img = window.URL.createObjectURL(data)
            this.model = true
            this.modelSrc = img
            formData.append('avatarUrl', img)
            // this.$http.post('https://www.mocky.io/v2/5cc8019d300000980a055e76', formData, { contentType: false, processData: false, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            updateUserInfo(formData)
            // .then(res => {
            //     console.log(res)
            // }).catch(err => {
            //     console.log(err)
            // })
              .then((response) => {
                console.log('upload response:', response)
                // var res = response.data
                // if (response.status === 'done') {
                //   _this.imgFile = ''
                //   _this.headImg = res.realPathList[0] // 完整路径
                //   _this.uploadImgRelaPath = res.relaPathList[0] // 非完整路径
                //   _this.$message.success('上传成功')
                //   this.visible = false
                // }
                _this.$message.success('上传成功')
                _this.$emit('ok', response.url)
                _this.visible = false
              }).catch(err => {
              console.log(err)
            })
            console.log(_this.userLoad, _this.recordId, this.modelSrc)
          })
        } else {
          this.$refs.cropper.getCropData((data) => {
            this.model = true
            this.modelSrc = data
          })
        }
      },
      okHandel () {
        const vm = this

        vm.confirmLoading = true
        setTimeout(() => {
          vm.confirmLoading = false
          vm.close()
          vm.$message.success('上传头像成功')
        }, 2000)
      },

      realTime (data) {
        this.previews = data
      }

    }
  }
</script>

<style lang="less" scoped>

  .avatar-upload-preview {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    width: 180px;
    height: 180px;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
